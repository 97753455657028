import './Faq.css'

import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ExpandMore } from '@mui/icons-material';

interface IAccordionContent {
  title: string;
  text: string;
}

const content: IAccordionContent[] = [
  {
    title: "Can I bring guests to 24GolfClub?",
    text: "Yes, with the Early Bird Membership (limited time offer) you can bring up to 3 guests for free. Please note that guests must be accompanied by a member at all times.",
  },
  {
    title: "Are we allowed to bring our own food and drinks?",
    text: "Yes, you are allowed to bring your own food and drinks. However alcohol is prohibited.",
  },
  {
    title: "Do I have access to 24GoldClub 24/7?",
    text: "Yes, you have the ability to schedule your golf session at any time of the day. Please note that the facility is not staffed 24/7. Please reach out to info@24golfclub.com for any questions or concerns.",
  },
  {
    title: "What is Uneekor?",
    text: "Uneekor is a revolutionary technology that provides you with the most accurate and reliable data on your golf swing. With Uneekor, you can analyze your spin and swing classification, learn about true distance, and improve your golf skills like never before.",
  },
  {
    title: "How can 24GolfClub help me improve as a golfer?",
    text: "We offer round-the-clock access to our state-of-the-art facility, complete with revolutionary Uneekor technology. At 24GolfClub, you’ll be able to analyze your spin and swing classification, learn about true distance, and transform into the confident golfer you’re meant to be.",
  },
  {
    title: "What is CourtReserve?",
    text: "Court Reserve is a software that allows you to book your golf simulator sessions online. It’s easy to use and ensures that you’ll always have a spot to practice your golf skills. Please checkout the mobile app for easy booking.",
  },
  {
    title: "How do I sign up for a membership?",
    text: "Follow this link: https://app.courtreserve.com/Online/Memberships/Public/12050 or please visit our website at 24golfclub.com and click on 'Become a Member' button. You can choose from a variety of membership options and sign up for the one that best suits your needs.",
  },
  {
    title: "How do I book a golf simulator session?",
    text: "Please visit our website at 24golfclub.com and click on 'Member Login'. Click 'Book' and you can choose from a variety of time slots and book the one that best suits your schedule.",
  },
  {
    title: "How do I cancel my membership?",
    text: "Please reach out info@24golfclub.com to cancel your membership. There is no cancelation notice period required. Please note that there are no refunds for the remaining days of the month.",
  },
]

function Faq() {
  return (
    <section>
      <div className="content-section">
        <div className="wrapper-global">

          <h2>Frequently Asked Questions</h2>

          {content.map( (content, index) => {
            return( 
            <Accordion
              key={`faq-${index}`}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`faq-${index}-content`}
                classes="faq-title"
              >
                <h4>{content.title}</h4>
              
              </AccordionSummary>
              <AccordionDetails>{content.text}</AccordionDetails>
            </Accordion>  
            )
          })}
        </div>
      </div>
    </section>
  );
}

export default Faq;
