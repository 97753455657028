
import Footer from "components/Footer/Footer";
import Header from "components/Header/Header";
import HomePage from "pages/HomePage/HomePage";
import FAQPage from "pages/FAQPage/FAQPage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />
  },
  {
    path: '/faq',
    element: <FAQPage />
  },
])

function App() {

  return (
    <>
      <Header />
      <div className="columns">
        <main>
          <RouterProvider router={router}/>
        </main>
      </div>
      <Footer />
    </>
  );
}

export default App;
