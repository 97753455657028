function Golf() {
  return (
    <div className="content-section">
      <div className="wrapper-global">
        <a href="https://uneekor.com">
          <h2>Our Simulators</h2>
        </a>
        <p>
        Uneekor excels in indoor golf solutions, offering precise launch monitors and simulators that provide detailed swing and ball data. This enables golfers to improve their game with accurate feedback, making every practice session more effective. Uneekor's technology transforms indoor spaces into high-quality practice environments, perfect for golfers of all levels.
        </p>
      </div>
    </div>
  );
}

export default Golf;
