import Faq from "components/FAQ/Faq"

const FAQPage = () => {

    return(
        <Faq />
    )

}

export default FAQPage