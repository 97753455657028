import { useState } from "react";
import 'components/Header/Header.css'
import logo from "assets/logo-green.png"; // Ensure you have the logo image in the correct path

interface IHeaderItems {
  title: string;
  href: string;
}

const HeaderItems: IHeaderItems[] = [
  {
    title: "Home",
    href: "/"
  },
  {
    title: "About",
    href: "/#about"
  },
  {
    title: "Membership",
    href: "/#membership"
  },
  // {
  //   title: "Contact",
  //   href: "#contact"
  // },
  // {
  //   title: "FAQs",
  //   href: '/faq'
  // }
]

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen(!isOpen);

  return (

    <header className="container flex">
        <div className="logo">
          <a href="/">
            <img src={logo} alt="24 Golf Club" />
          </a>
        </div>
        <nav>
          <ul className={isOpen ? "show" : ""}>
            {HeaderItems.map( header => {
              return (
                <li>
                  <a href={header.href} className="header-link">{header.title}</a>
                </li>
              )
            })}
          </ul>
        </nav>
        <div>
          <a className="login-button"
            href="https://app.courtreserve.com/Online/Account/LogIn/12050"
          >
            Member Login
          </a>
        </div>
        <div className="hamburger" onClick={toggleMenu}>
          &#9776;
        </div>
    </header>
  );
}

export default Header;
