import "components/Membership/Membership.css"

function Membership() {
  return (
    <div className="content-section">
      <div className="wrapper-global">
        <a href="https://app.courtreserve.com/Online/Memberships/Public/12050">
          <h2>Membership Plans</h2>
        </a>
        <p className="membership-limited">
          We are only allowing a certain number of members to join our club to ensure everyone has a good experience making reservations and enjoying our facilities. Don't miss out on this exclusive opportunity!
        </p>
        <a className="right-title" href="https://app.courtreserve.com/Online/Memberships/Public/12050">
          <h2>Individual Memberships</h2>
        </a>
        <table className="pricing-table">
            <tr>
                <th>Features</th>
                <th className="plan-title">Early Bird</th>
                <th className="plan-title">3 Day Trial</th>
            </tr>
            <tr className="feature-row">
                <td className="feature">Access</td>
                <td>24/7 Access</td>
                <td>24/7 Access</td>
            </tr>
            <tr className="feature-row">
                <td className="feature">Outstanding Bookings</td>
                <td>2</td>
                <td>2</td>
            </tr>
            <tr className="feature-row">
                <td className="feature">Minutes of use per day</td>
                <td>120 minutes</td>
                <td>120 minutes</td>
            </tr>
            <tr className="feature-row">
                <td className="feature">Sessions per day</td>
                <td>1</td>
                <td>1</td>
            </tr>
            <tr className="feature-row">
                <td className="feature">Initiation Fee</td>
                <td className="price">$0</td>
                <td className="price">$0</td>
            </tr>
            <tr className="feature-row">
                <td className="feature">Price</td>
                <td className="price">$149.00 / Monthly</td>
                <td className="price">$30.00 / One Time</td>
            </tr>
            <tr className="feature-row">
                <td className="feature">Join Us!</td>
                <td>
                  <a href="https://app.courtreserve.com/Online/Memberships/ViewPublicMembership/12050?membershipId=115934">
                    <button className="membership-join">Join</button>
                  </a>
                </td>
                <td>
                  <a href="https://app.courtreserve.com/Online/Memberships/ViewPublicMembership/12050?membershipId=117622">
                    <button className="membership-join">Join</button>
                  </a>
                </td>
            </tr>
        </table>
        
        <p className="price-notice">
          Hurry! These prices are only for early members. As we get more members, the prices will go up to the regular price of <strong>$199/month</strong>.
        </p>

        <a className="right-title" href="https://app.courtreserve.com/Online/Memberships/Public/12050">
          <h2 >Family Memberships</h2>
        </a>

        <table className="pricing-table">
            <tr>
                <th>Features</th>
                {/* <th className="plan-title popular">4 Members</th> */}
                <th className="plan-title">3 Members</th>
                <th className="plan-title">2 Members</th>
            </tr>
            <tr className="feature-row">
                <td className="feature">Access</td>
                {/* <td>24/7 Access</td> */}
                <td>24/7 Access</td>
                <td>24/7 Access</td>
            </tr>
            <tr className="feature-row">
                <td className="feature">Outstanding Bookings</td>
                {/* <td>6</td> */}
                <td>5</td>
                <td>4</td>
            </tr>
            <tr className="feature-row">
                <td className="feature">Minutes of use per day</td>
                {/* <td>360 minutes</td> */}
                <td>300 minutes</td>
                <td>240 minutes</td>
            </tr>
            <tr className="feature-row">
                <td className="feature">Sessions per day</td>
                {/* <td>4</td> */}
                <td>3</td>
                <td>2</td>
            </tr>
            <tr className="feature-row">
                <td className="feature">Initiation Fee</td>
                {/* <td className="price">$0</td> */}
                <td className="price">$0</td>
                <td className="price">$0</td>
            </tr>
            <tr className="feature-row">
                <td className="feature">Price</td>
                {/* <td className="price">$479.00 / Monthly </td> */}
                <td className="price">$389.00 / Monthly </td>
                <td className="price">$279.00 / Monthly </td>
            </tr>
            <tr className="feature-row">
                <td className="feature">Join Us!</td>
                {/* <td>
                  <a href="https://app.courtreserve.com/Online/Memberships/ViewPublicMembership/12050?membershipId=117753">
                    <button className="membership-join">Join</button>
                  </a>
                </td> */}
                <td>
                  <a href="https://app.courtreserve.com/Online/Memberships/ViewPublicMembership/12050?membershipId=117754">
                    <button className="membership-join">Join</button>
                  </a>
                </td>
                <td>
                  <a href="https://app.courtreserve.com/Online/Memberships/ViewPublicMembership/12050?membershipId=117755">
                    <button className="membership-join">Join</button>
                  </a>
                </td>
            </tr>
        </table>
      </div>
    </div>
  );
}

export default Membership;
