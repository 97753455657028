import './Hero.css'

function Hero() {
  return (
    <div className="hero">
      <div className="container wrapper-global">
        <h1>24 GOLF CLUB</h1>
        <h2>LYNNWOOD, WA</h2>
        <a href="https://app.courtreserve.com/Online/Memberships/Public/12050">
          <button className="new-member">Become a Member</button>
        </a>
      </div>
    </div>
  );
}

export default Hero;
