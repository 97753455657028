import About from "components/About/About";
import Hero from "components/Hero/Hero";
import Membership from "components/Membership/Membership";
import Golf from "components/Simulators/Simulators";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function HomePage (){

    //Allows application to navigate users to Hashtag location after navigating from another page - e.g. from faq to Membership
    const { pathname, hash, key } = useLocation();

    useEffect(() => {
      // if not a hash link, scroll to top
      if (hash === '') window.scrollTo(0, 0);
      // else scroll to id
      else {
        setTimeout(() => {
          const id = hash.replace('#', '');
          const element = document.getElementById(id);
          if (element) element.scrollIntoView();
        }, 0);
      }
    }, [pathname, hash, key]); // do this on route change


    return (
    <>
      <div className="container-global">
        <section id="home" >
          <Hero/>
        </section>
        <section id="about">
          <About />
        </section>
        <section id="membership">
          <Membership />
        </section>
        <section id="golf">
          <Golf />
        </section>
        {/* <section id="contact">
          <Contact />
        </section> */}
      </div>
    </>

    )

}